import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { FaMapMarkedAlt, FaEnvelope, FaPhoneAlt, FaWhatsapp, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'

const Footer = ({ learningMethods, studyFields, socialMediaLinks, quickLinks, contactInfo, siteLogo }) => {
  const currentYear = new Date().getFullYear()

  return (
    <StyledFooter>
      <div className="content">
        <div className="logo">
          <GatsbyImage 
            image={getImage(siteLogo.asset)}
            imgStyle={{ objectFit: 'contain', width: '100%', height: '100%' }}
            alt="MassCollege" 
          />
        </div>
        <div className="address">
          <ul id='contact-info-list'>
            <li>
              <FaMapMarkedAlt />
              <span dangerouslySetInnerHTML={{__html: contactInfo.address.replaceAll('\n', '<br>')}} />
            </li>
          </ul>
        </div>
        <div className="contact">
          <ul>
            <li>
              <FaEnvelope />
              <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
            </li>
            <li>
              <FaPhoneAlt />
              <a href={`tel:${contactInfo.telephoneNumber.replaceAll(' ', '')}`}>{contactInfo.telephoneNumber}</a>
            </li>
            <li>
              <FaWhatsapp />
              <a href={`https://wa.me/+27${contactInfo.whatsappNumber.replaceAll(' ', '')}`} target='_blank' rel='noreferrer'>{contactInfo.whatsappNumber}</a>
            </li>
          </ul>
          {socialMediaLinks && socialMediaLinks.facebook && (
            <a href={socialMediaLinks.facebook} title='Facebook' aria-label='Facebook' target='_blank' rel='noreferrer'>
              <FaFacebook />
            </a>
          )}
          {socialMediaLinks && socialMediaLinks.instagram && (
            <a href={socialMediaLinks.instagram} title='Instagram' aria-label='Instagram' target='_blank' rel='noreferrer'>
              <FaInstagram />
            </a>
          )}
          {socialMediaLinks && socialMediaLinks.youtube && (
            <a href={socialMediaLinks.youtube} title='YouTube' aria-label='YouTube' target='_blank' rel='noreferrer'>
              <FaYoutube />
            </a>
          )}
        </div>
        {quickLinks && quickLinks.length > 0 && (
          <div className="quick-links">
            <h3>Quick Links</h3>
            <ul>
              {quickLinks?.map(({title, slug, url}) => (
                slug?.current 
                ? <li key={slug?.current}><Link to={`/${slug?.current}/`}>{title}</Link></li>
                : <li key={slug?.current}><a href={url}>{title}</a></li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <p className="copyright">&copy; Mass College {currentYear} | Designed by <a href='https://itgenie.co.za' target='_blank' rel='noreferrer'>IT Genie</a></p>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: var(--secondary-color);
  color: #f4f4f4;
  padding: 2rem 4rem 4rem 4rem;
  font-size: 0.9rem;

  h3 {
    color: inherit;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }

  p {
    color: inherit;
  }

  ul {
    color: inherit;
    margin-bottom: 1.5rem;
    list-style: none;

    li {
      line-height: 1.5;
    }

    svg {
      font-size: 1.2em;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
    }
  }

  img#logo {
    width: 90%;
    object-fit: contain;
  }

  svg {
    font-size: 1.9em;
    margin-right: 1rem;
  }

  div.content {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns */
    grid-gap: 2rem;
    margin-bottom: 2rem;
    align-items: center; /* Center items vertically */

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    /* Adjust specific items to span two rows */
    .social-links,
    .quick-links {
      grid-column: span 2; /* Social Links and Quick Links span two columns */
    }
  }

  @media (max-width: 480px) {
    padding: 2rem;

    div.content {
      & > div {
        margin-top: 0 !important;
      }
    }
  }
`

export default Footer
