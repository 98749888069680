import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Navbar from './Navbar'
import Footer from './Footer'
import BottomNav from './BottomNav'
import { FaWhatsapp } from 'react-icons/fa'
// import Seo from '../Seo'

import * as styles from '../../styles/components/layout.module.scss'

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allSanityLearningMethod(sort: {fields: rank}) {
        nodes {
          title
          slug { current }
        }
      }
      allSanityStudyField(sort: {fields: title}) {
        nodes {
          title
          slug { current }
        }
      }
      sanityCompanyInfo {
        address
        email
        telephoneNumber
        whatsappNumber
        socialMediaLinks {
          facebook
          instagram
          youtube
        }
      }
      sanitySiteSettings {
        title
        logo {
          asset {
            gatsbyImageData
          }
        }
        quickLinks {
          title
          slug { current }
          url
        }
        nextStepLinks {
          text
          link {
            _key
            _type
            linkType
            href
            blank
            _rawInternalLink
          }
        }
      }
    }
  `)

  const allLearningMethods = data.allSanityLearningMethod.nodes
  const allStudyFields = data.allSanityStudyField.nodes
  const { socialMediaLinks, ...contactInfo } = data.sanityCompanyInfo
  const siteLogo = data.sanitySiteSettings.logo
  const quickLinks = data.sanitySiteSettings.quickLinks
  const nextStepLinks = data.sanitySiteSettings.nextStepLinks


  return (
    <>
      {/* <Seo /> */}
      <Helmet 
        htmlAttributes={{
          lang: 'en-ZA',
        }}
        title={data.sanitySiteSettings.title} 
      />
      <Navbar nextStepLinks={nextStepLinks} siteLogo={siteLogo} location={location} />
      {children}
      <Footer learningMethods={allLearningMethods} studyFields={allStudyFields} socialMediaLinks={socialMediaLinks} quickLinks={quickLinks} contactInfo={contactInfo} siteLogo={siteLogo} />
      <BottomNav />
      <Link
        href={`https://wa.me/${contactInfo.whatsappNumber}`}
        style={{
          backgroundColor: '#25d366',
          color: '#fff',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          textDecoration: 'none',
          zIndex: 1000,
        }}
        className={styles.whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </Link>
    </>
  )
}

export default Layout