exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accreditations-js": () => import("./../../../src/pages/accreditations.js" /* webpackChunkName: "component---src-pages-accreditations-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-courses-sanity-course-slug-current-js": () => import("./../../../src/pages/courses/{sanityCourse.slug__current}.js" /* webpackChunkName: "component---src-pages-courses-sanity-course-slug-current-js" */),
  "component---src-pages-document-js": () => import("./../../../src/pages/document.js" /* webpackChunkName: "component---src-pages-document-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gallery-sanity-gallery-slug-current-js": () => import("./../../../src/pages/gallery/{sanityGallery.slug__current}.js" /* webpackChunkName: "component---src-pages-gallery-sanity-gallery-slug-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-campus-js": () => import("./../../../src/pages/our-campus.js" /* webpackChunkName: "component---src-pages-our-campus-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-upcoming-courses-js": () => import("./../../../src/pages/upcoming-courses.js" /* webpackChunkName: "component---src-pages-upcoming-courses-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-course-page-js": () => import("./../../../src/templates/course-page.js" /* webpackChunkName: "component---src-templates-course-page-js" */)
}

