import React from 'react'
import { Link } from 'gatsby'
import * as styles from './bottomNav.module.scss'
import { FaGraduationCap, FaCalendar, FaClipboard, FaQuestionCircle } from "react-icons/fa"


const BottomNav = () => {
    return (
        <nav className={styles.bottomNav}>
            <ul className={styles.navItems}>
                <li>
                    <Link to='/courses/'>
                        <FaGraduationCap style={{ fontSize: '1.5rem'}} />
                        <span>Courses</span>
                    </Link>
                </li>
                <li>
                    <Link to='/upcoming-courses/'>
                        <FaCalendar style={{ fontSize: '1.5rem'}} />
                        <span>Upcoming Courses</span>
                    </Link>
                </li>
                <li>
                    <Link to='https://forms.gle/HwScjepK9bfTdrir7'>
                        <FaClipboard style={{ fontSize: '1.5rem'}} />
                        <span>Apply</span>
                    </Link>
                </li>
                <li>
                    <Link to='/contact/'>
                        <FaQuestionCircle style={{ fontSize: '1.5rem'}} />
                        <span>Enquire</span>
                    </Link>
                </li>
            </ul>
        </nav>
  )
}

export default BottomNav
