// extracted by mini-css-extract-plugin
export var contactLinks = "Navbar-module--contact-links--6bb79";
export var coursesItem = "Navbar-module--coursesItem--5963e";
export var dontShow = "Navbar-module--dontShow--52890";
export var dropdownList = "Navbar-module--dropdown-list--e4512";
export var logoContainer = "Navbar-module--logo-container--3db23";
export var logoImage = "Navbar-module--logoImage--e7ba6";
export var menuBtn = "Navbar-module--menuBtn--1fe3c";
export var mobileContactLinks = "Navbar-module--mobile-contact-links--abd43";
export var navbar = "Navbar-module--navbar--97dbb";
export var navbarContainer = "Navbar-module--navbar-container--ad450";
export var navbarContent = "Navbar-module--navbar-content--6fc1c";
export var showDropdown = "Navbar-module--showDropdown--77715";
export var showMenu = "Navbar-module--show-menu--4cc27";
export var upcomingCourses = "Navbar-module--upcomingCourses--1809c";